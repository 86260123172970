import Badge from "@/admin/experiences/badges/Badge"
import { useLabel } from "@/core/context/LabelsContext"
import { ProductType } from "@/core/context/__generated__/ActiveProductContextFragment.graphql"
import ProductDropdownItem from "@/product/common/ProductDropdownItem"
import {
  ProductRegistrationAvailability,
  ProductSelectQuery,
} from "@/product/common/__generated__/ProductSelectQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoIcon,
  DiscoInputSkeleton,
  DiscoSelect,
  DiscoTooltip,
  SelectOption,
} from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useLazyLoadQuery } from "react-relay"

interface Props extends TestIDProps {
  value: GlobalID | null | undefined
  onChange: (productId: GlobalID | null) => void
  organizationId: GlobalID
  emptyText?: string
  type?: ProductType
  includeAvailabilities?: ProductRegistrationAvailability[]
  hideAutoJoin?: boolean
  hideNonPublic?: boolean
  hideNonMemberProducts?: boolean
  hideNonEventAppProducts?: boolean
  viewerPermission?: string
  productIds?: GlobalID[]
  placeholder?: string
  emptyTooltipContent?: React.ReactNode
  showStartAdornment?: boolean
  showDraftStatus?: boolean
}

function ProductSelect(props: Props) {
  const {
    value,
    onChange,
    organizationId,
    emptyText,
    type = "course",
    includeAvailabilities,
    hideAutoJoin = false,
    hideNonPublic = true,
    hideNonMemberProducts = false,
    hideNonEventAppProducts = false,
    viewerPermission,
    placeholder: customPlaceholder,
    productIds,
    showStartAdornment = false,
    testid = "ProductSelect",
    emptyTooltipContent,
    showDraftStatus = false,
  } = props
  const experienceLabel = useLabel("admin_experience")
  const classes = useStyles()

  const { organization } = useLazyLoadQuery<ProductSelectQuery>(
    graphql`
      query ProductSelectQuery(
        $id: ID!
        $type: String
        $hideNonPublic: Boolean!
        $ids: [ID!]
        $viewerIsMember: Boolean
        $hideNonEventAppProducts: Boolean!
      ) {
        organization: node(id: $id) {
          ... on Organization {
            products(
              type: $type
              hideNonPublic: $hideNonPublic
              ids: $ids
              viewerIsMember: $viewerIsMember
            ) {
              edges {
                node {
                  id
                  name
                  slug
                  isAutoJoined
                  viewerPermissions
                  registrationAvailability
                  badge {
                    ...BadgeFragment
                  }
                  hasEventsApp: hasProductApp(kind: events)
                    @include(if: $hideNonEventAppProducts)
                  ...ProductDropdownItemFragment
                }
              }
            }
          }
        }
      }
    `,
    {
      id: organizationId,
      type,
      hideNonPublic,
      ids: productIds,
      viewerIsMember: hideNonMemberProducts ? true : null,
      hideNonEventAppProducts,
    },
    { fetchPolicy: "store-and-network" }
  )

  const products = Relay.connectionToArray(organization?.products).filter((p) => {
    if (viewerPermission) {
      if (!p.viewerPermissions.includes(viewerPermission)) return false
    }
    if (hideNonEventAppProducts) {
      return Boolean(p.hasEventsApp)
    }
    if (includeAvailabilities)
      return includeAvailabilities.includes(p.registrationAvailability)
    if (hideAutoJoin) return !p.isAutoJoined
    return Boolean(p)
  })

  const productsById = ArrayUtils.mapBy(products, "id")
  const noProducts = products.length === 0
  let placeholder = customPlaceholder || `Select ${experienceLabel.singular}`
  if (noProducts) {
    placeholder = emptyText || "None found"
  }

  const productOptions = products.map((product) => ({
    value: product.id,
    title: product.name,
    searchable: [product.name, product.slug],
  }))

  const options = productOptions

  return (
    <DiscoTooltip
      titleVariant={"body-sm-600"}
      title={noProducts ? "No Product Available" : null}
      content={noProducts ? emptyTooltipContent : null}
    >
      <span>
        <DiscoSelect
          testid={`${testid}.select`}
          textFieldInputProps={{
            startAdornment: renderStartAdornment(),
            classes: {
              adornedStart: classes.startAdornment,
            },
          }}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disableClearable
          options={options}
          renderOption={renderOption}
          renderValue={renderValue}
          disabled={noProducts}
        />
      </span>
    </DiscoTooltip>
  )

  function renderStartAdornment() {
    if (!value || !showStartAdornment) return null
    const product = productsById[value]
    if (!product) return null
    return <Badge badgeKey={product.badge} size={24} />
  }

  function renderOption(option: SelectOption<GlobalID>) {
    // Option to deselect product
    if (!option.value) {
      return (
        <DiscoDropdownItem
          testid={testid}
          icon={<DiscoIcon icon={"globe"} />}
          title={"Community Event"}
          backgroundColor={"transparent"}
          hoverBackgroundColor={"transparent"}
        />
      )
    }
    const product = productsById[option.value]
    return (
      <ProductDropdownItem
        testid={`${testid}.option.${option.title}`}
        productKey={product}
        showDraftStatus={showDraftStatus}
        hoverBackgroundColor={"transparent"}
      />
    )
  }

  function renderValue(option: SelectOption<GlobalID>) {
    const product = productsById[option.value]

    return (
      <ProductDropdownItem
        testid={`${testid}.option.${option.title}`}
        productKey={product}
        hoverBackgroundColor={"transparent"}
      />
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  // Target the first div if there's a start adornment
  startAdornment: {
    "& div:first-child": {
      marginRight: theme.spacing(1),
    },
  },
}))

function ProductSelectSkeleton() {
  return <DiscoInputSkeleton />
}

export default Relay.withSkeleton<Props>({
  component: ProductSelect,
  skeleton: ProductSelectSkeleton,
})
